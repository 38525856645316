import {Components} from '@exp1/front-utils'

export const Sidebar = () => {
  return (
    <div className="flex flex-col gap-4 border rounded p-4 w-64 h-min">
      <Components.StandardLink to="/products">Products</Components.StandardLink>
      <Components.StandardLink to="/locations">Locations</Components.StandardLink>
    </div>
  )
}
