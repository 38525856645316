import {Components, Hooks} from '@exp1/front-utils'
import {useDebounce} from '@uidotdev/usehooks'
import {UsingPill} from 'components/UsingPill'
import {locationsHooks} from 'hooks/locations'
import {productsHooks} from 'hooks/products'
import {keyBy} from 'lodash-es'
import {useState} from 'react'

export const List = () => {
  const locationListQuery = locationsHooks.useListQuery()

  const [searchString, setSearchString] = useState('')
  const debouncedSearchString = useDebounce(searchString, 1000)

  const paginatedProductListQuery = Hooks.useInfinitiveLoading({
    limit: 50,
    ordering: ['reference'],
    usePaginatedListQuery: productsHooks.usePaginatedListQuery.bind(productsHooks),
    options: {
      refetchOnMount: false,
      enabled: !debouncedSearchString,
    },
  })

  const filteredProductListQuery = productsHooks.useListQuery(
    {
      filters: [
        {field: 'reference', value: debouncedSearchString, op: '>='},
        {field: 'reference', value: debouncedSearchString + '~', op: '<='},
      ],
      ordering: ['reference'],
    },
    {
      enabled: !!debouncedSearchString,
    }
  )

  const headers = [
    {key: 'reference', value: 'Reference'},
    {key: 'capacity', value: 'Capacity'},
    {key: 'collectData', value: 'Collect data'},
    {key: 'location', value: 'Location'},
    {key: 'using', value: 'Using'},
    {key: 'edit'},
  ]

  if (
    !locationListQuery.data ||
    (debouncedSearchString && !paginatedProductListQuery.data) ||
    (!debouncedSearchString && filteredProductListQuery.data)
  ) {
    return null
  }

  const locationMap = keyBy(locationListQuery.data, 'id')

  const data = (debouncedSearchString ? filteredProductListQuery.data : paginatedProductListQuery.data) || []

  const rows = data.map((product) => ({
    key: product.id,
    columns: {
      reference: product.reference,
      capacity: product.extensions.common?.capacity || '-',
      collectData: product.extensions.analytics?.collectData ? 'Yes' : 'No',
      location: locationMap[product.locationId].name,
      using: <UsingPill using={product.using} />,
      edit: <Components.EditLink to={`/products/${product.id}/edit`} />,
    },
  }))

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <Components.Header.H5>Products</Components.Header.H5>
        <div className="flex gap-2 items-center">
          <Components.Label>Search by reference</Components.Label>
          <Components.SearchInput size="sm" onChange={(event) => setSearchString(event.target.value)} />
        </div>
      </div>
      <Components.Table headers={headers} rows={rows} />
    </div>
  )
}
