import {Components} from '@exp1/front-utils'
import {optionHooks} from 'hooks/options'
import {useId} from 'hooks/useId'
import {get} from 'lodash-es'

export const Edit = () => {
  const id = useId()
  const itemQuery = optionHooks.useItemQuery(id)

  if (!itemQuery.data) {
    return null
  }

  return (
    <div className="flex flex-col gap-6">
      <Components.Header.H5>{`Option [${itemQuery.data.reference}] ${itemQuery.data.ventrataOption.title}`}</Components.Header.H5>

      <div className="flex flex-col gap-4">
        <Components.Header.H6>Start times</Components.Header.H6>
        <div className="flex gap-4">
          {get(itemQuery.data.ventrataOption, 'availabilityLocalStartTimes', []).map((startTime) => {
            return (
              <div
                key={startTime}
                className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full"
              >
                {startTime}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
