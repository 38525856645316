import {Utils} from '@exp1/front-utils'
import {productApi} from 'api'
import {Params, Responses} from 'commonTypes/products'

export const productsHooks = new Utils.CrudHooks<
  never,
  Params.Update,
  Responses.List,
  Responses.Item,
  never,
  Responses.Update
>('product', 'Product', productApi)
