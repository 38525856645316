import {User} from '@exp1/common-utils'
import {Types} from '@exp1/front-utils'
import {iAm} from 'api'
import {AxiosError} from 'axios'
import {useQuery} from 'react-query'

export const useIAmQuery = (options: Types.QueryOptions<User, AxiosError>) => {
  return useQuery(['iAm'], () => iAm(), {
    cacheTime: 0,
    ...options,
  })
}
