import {Utils} from '@exp1/front-utils'
import {optionApi} from 'api'
import {Params, Responses} from 'commonTypes/options'

export const optionHooks = new Utils.CrudHooks<
  never,
  Params.Update,
  Responses.List,
  Responses.Item,
  never,
  Responses.Update
>('option', 'Option', optionApi)
