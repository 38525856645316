import {Components, Fields} from '@exp1/front-utils'
import {Entities as VPEntities, Extensions} from '@exp1/ventrata-products-utils'
import {optionHooks} from 'hooks/options'
import {productsHooks} from 'hooks/products'
import {useId} from 'hooks/useId'
import {FC} from 'react'
import {Form} from 'react-final-form'

const CommonExtension = () => {
  return (
    <>
      <Fields.InputField
        label="Capacity"
        name="capacity"
        id="common_capacity"
        type="number"
        min={0}
        className="!w-24"
        parse={(value: string) => parseInt(value, 10)}
      />
    </>
  )
}

const AnalyticsExtension = () => {
  return (
    <>
      <Fields.CheckboxField label="Collect data" name="collectData" id="analitics_collectData" />
    </>
  )
}

const EXTENSION_COMPONENTS: Record<string, FC> = {
  common: CommonExtension,
  analytics: AnalyticsExtension,
}

export const Edit = () => {
  const id = useId()
  const updateMutation = productsHooks.useUpdateMutation()
  const itemQuery = productsHooks.useItemQuery(id)

  const optionListQuery = optionHooks.useListQuery(
    {
      filters: [
        {field: 'ventrataProductId', value: itemQuery.data?.ventrataProduct.id || 'INVALID_VALUE'},
        {field: 'using', value: 'active'},
      ],
      ordering: ['reference'],
    },
    {
      enabled: !!itemQuery.data,
    }
  )

  const optionHeaders = [{key: 'reference', value: 'Reference'}, {key: 'show'}]

  if (!itemQuery.data || !optionListQuery.data) {
    return null
  }

  const onSubmit = (extensions: VPEntities.Product.Extensions) => {
    updateMutation.mutate(
      {id, extensions},
      {
        onSuccess: () => {
          itemQuery.remove()
        },
      }
    )
  }

  const optionRows = optionListQuery.data.map((productOption) => ({
    key: productOption.id,
    columns: {
      reference: productOption.reference,
      show: (
        <div className="flex justify-end">
          <Components.StandardLink to={`/options/${productOption.id}/edit`}>Show</Components.StandardLink>
        </div>
      ),
    },
  }))

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <Components.Header.H5>{`Product [${itemQuery.data.reference}] ${itemQuery.data.ventrataProduct.title}`}</Components.Header.H5>

        {Extensions.product.map((extensionCode) => {
          const ExtensionComponent = EXTENSION_COMPONENTS[extensionCode]

          return (
            <Form
              key={extensionCode}
              keepDirtyOnReinitialize
              initialValues={itemQuery.data.extensions[extensionCode as typeof Extensions.product[number]]}
              onSubmit={(values) => onSubmit({...itemQuery.data.extensions, [extensionCode]: values})}
              render={({handleSubmit, pristine, values}) => {
                console.log({values})
                return (
                  <form
                    onSubmit={handleSubmit}
                    className="flex flex-col gap-4 bg-gray-50 rounded-lg p-4 border border-gray-300"
                  >
                    <div className="flex justify-between items-center">
                      <Components.Header.H6>{`Edit extension '${extensionCode}'`}</Components.Header.H6>
                      <Fields.CheckboxField label="Enabled" name="isEnabled" id={`${extensionCode}_isEnabled`} />
                    </div>

                    <ExtensionComponent />

                    <Components.Button disabled={pristine} className="ml-auto">
                      Save
                    </Components.Button>
                  </form>
                )
              }}
            />
          )
        })}
      </div>

      <div className="flex flex-col gap-4">
        <Components.Header.H6>Product options</Components.Header.H6>
        <Components.Table headers={optionHeaders} rows={optionRows} />
      </div>
    </div>
  )
}
