import {Components} from '@exp1/front-utils'
import {Using} from 'commonTypes/common'
import {capitalize} from 'lodash-es'

type Props = {
  using: Using
}

export const UsingPill = ({using}: Props) => {
  return (
    <Components.Pill className={using === 'active' ? 'bg-green-500' : 'bg-red-500'}>
      {capitalize(using)}
    </Components.Pill>
  )
}
