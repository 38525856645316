import {Components} from '@exp1/front-utils'
import {UsingPill} from 'components/UsingPill'
import {locationsHooks} from 'hooks/locations'

export const List = () => {
  const listQuery = locationsHooks.useListQuery({})

  const headers = [
    {key: 'code', value: 'Code'},
    {key: 'name', value: 'Name'},
    {key: 'timeZone', value: 'Time zone'},
    {key: 'using', value: 'Using'},
    {key: 'edit'},
  ]

  if (!listQuery.data) {
    return null
  }

  const rows = listQuery.data.map((location) => ({
    key: location.id,
    columns: {
      code: location.code,
      name: location.name,
      timeZone: location.timeZone,
      using: <UsingPill using={location.using} />,
      edit: <Components.EditLink to={`/locations/${location.id}/edit`} />,
    },
  }))

  return (
    <div className="flex flex-col gap-4">
      <Components.Header.H5>Locations</Components.Header.H5>
      <Components.Table headers={headers} rows={rows} />
    </div>
  )
}
