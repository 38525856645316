import {Navbar} from 'components/Navbar'
import {Sidebar} from 'components/Sidebar'
import * as Locations from 'pages/locations'
import * as Options from 'pages/options'
import * as Products from 'pages/products'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'

const Dashboard = () => {
  return (
    <>
      <Navbar />
      <div className="w-[1024px] m-auto p-4">
        <div className="flex gap-8">
          <Sidebar />
          <div className="w-full">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}

export const Routing = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Dashboard />}>
          <Route path="products" element={<Products.List />} />
          <Route path="products/:id/edit" element={<Products.Edit />} />
          <Route path="options/:id/edit" element={<Options.Edit />} />
          <Route path="locations" element={<Locations.List />} />
          <Route path="locations/:id/edit" element={<Locations.Edit />} />
          <Route path="/" element={<Navigate to="/products" replace={true} />} />
        </Route>
      </Routes>
    </div>
  )
}
