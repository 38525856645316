import {Components, Fields} from '@exp1/front-utils'
import {Extensions, Entities as VPEntities} from '@exp1/ventrata-products-utils'
import {locationsHooks} from 'hooks/locations'
import {useId} from 'hooks/useId'
import {FC} from 'react'
import {Form} from 'react-final-form'

const Ventrata2CalendarExtension = () => {
  return (
    <>
      <Fields.InputField
        label="Booking calendar id"
        name="bookingCalendarId"
        id="bookingCalendarId"
        type="text"
        spellCheck={false}
        expanded
      />
      <Fields.InputField
        label="Guide calendar id"
        name="guideCalendarId"
        id="guideCalendarId"
        type="text"
        spellCheck={false}
        expanded
      />
    </>
  )
}

const EXTENSION_COMPONENTS: Record<string, FC> = {
  ventrata2calendar: Ventrata2CalendarExtension,
}

export const Edit = () => {
  const id = useId()
  const updateMutation = locationsHooks.useUpdateMutation()
  const itemQuery = locationsHooks.useItemQuery(id)

  if (!itemQuery.data) {
    return null
  }

  const onSubmit = (extensions: VPEntities.Location.Extensions) => {
    updateMutation.mutate(
      {id, extensions},
      {
        onSuccess: () => {
          itemQuery.remove()
        },
      }
    )
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <Components.Header.H5>{`Locations [${itemQuery.data.code}] ${itemQuery.data.name}`}</Components.Header.H5>

        {Extensions.location.map((extensionCode) => {
          const ExtensionComponent = EXTENSION_COMPONENTS[extensionCode]

          return (
            <Form
              key={extensionCode}
              keepDirtyOnReinitialize
              initialValues={itemQuery.data.extensions[extensionCode]}
              onSubmit={(values) => onSubmit({...itemQuery.data.extensions, [extensionCode]: values})}
              render={({handleSubmit, pristine}) => {
                return (
                  <form
                    onSubmit={handleSubmit}
                    className="flex flex-col gap-4 bg-gray-50 rounded-lg p-4 border border-gray-300"
                  >
                    <div className="flex justify-between items-center">
                      <Components.Header.H6>{`Edit extension '${extensionCode}'`}</Components.Header.H6>
                      <Fields.CheckboxField label="Enabled" name="isEnabled" id={`${extensionCode}_isEnabled`} />
                    </div>

                    <ExtensionComponent />

                    <Components.Button disabled={pristine} className="ml-auto">
                      Save
                    </Components.Button>
                  </form>
                )
              }}
            />
          )
        })}
      </div>
    </div>
  )
}
